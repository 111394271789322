import React from 'react';
import './css/theme.css'; // Asegúrate de incluir el CSS apropiado

const CamareroDigital = () => {
  return (
    <section data-bs-version="5.1" className="header4 cid-tSRNBsmrYp" id="header04-3">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md">
            <div className="text-wrapper">
              <h2 className="mbr-section-title mb-4 mbr-fonts-style display-2">
                <strong>Intro with video</strong>
              </h2>
              <p className="mbr-text mb-4 mbr-fonts-style display-7">
                Intro with color, paddings and a video on the right. Mobirise helps you cut down development
                time by providing you with a flexible website editor with a drag and drop interface.
              </p>
              <div className="mbr-section-btn mb-4">
                <button className="btn btn-primary display-7">Learn more</button>
              </div>
            </div>
          </div>
          <div className="mbr-figure col-12 col-md-8">
            <iframe className="mbr-embedded-video" title="Unique Title" src="https://player.vimeo.com/video/874857796?autoplay=0&amp;loop=1" width="1280" height="720" frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CamareroDigital;
