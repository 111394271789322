import React, { useState } from 'react';
import axios from 'axios';
import './css/fileupload.css'; // Asegúrate de que la ruta sea la correcta

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    //pasa también el nombbre del usuario
    //formData.append('avatar', avatar);

  
    try {
      const res = await axios.post(
        'https://ceu-chatcompletion-python.azurewebsites.net/api/fileblobupload',
        //'http://localhost:7071/api/FileBlobUpload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('File uploaded successfully:', res.data);
      setUploadStatus('Archivo Subido'); // Set the upload status
    } catch (err) {
      console.log('There was an error uploading the file:', err);
      setUploadStatus('There was an error uploading the file.');
    }
  };

  return (
      <form onSubmit={onSubmit}>
          <div className="file-upload-component">
              <input id="file-upload" className="file-input" type="file" onChange={onFileChange} />
              <label htmlFor="file-upload" className="file-upload-label">Seleccionar archivo</label>
              <button className="upload-button" type="submit">Upload</button>
          </div>
         
          {uploadStatus && <p>{uploadStatus}</p>} {/* Muestra el estado de la carga */}
      </form>

  );
};


export default FileUpload;
