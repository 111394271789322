import React from "react";

//import Teams from "./Teams.jsx"; // Asegúrate de que la ruta del archivo sea correcta y coincida con la ubicación real del archivo.
//import Menu from "./Menu.jsx";
//import Header from "./Header.jsx";
//import Tarjetas from "./Tarjetas.jsx";

//import Teams from "./Teams.jsx";
import AvatarApp from "./AvatarApp.jsx";
import TeacherGallery from "./TeachersGallery.jsx";
//import "./App.css";
//import Autenticador from "./Autenticador.jsx";
import CamareroDigital from "./CamareroDigital.jsx";



//import Autenticador from './Autenticador';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Home from './Home'; // Asegúrate de que la ruta de importación sea la correcta
import FileUpload from "./FileUpload.jsx";



const App = () => {
  
 ;
  return (

    <Router>
      
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/teachers" element={<TeacherGallery />} />
        <Route path="/avatar" element={<AvatarApp />} />
        <Route path="/camarerodigital" element={<CamareroDigital />} />
        <Route path="/fileupload" element={<FileUpload />} />
        

        
      </Routes>
    </Router>

  );
};

export default App;
//  {AvatarApp()} 