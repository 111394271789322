import React, { useState, useEffect, useRef } from "react";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import ReactPlayer from 'react-player';

//import FileUpload from './FileUpload';
import "./css/avatar.css";


const AvatarApp = () => {
  const [displayText, setDisplayText] = useState("Pulsa una vez para hablar.");
  //const chatInputRef = useRef(null);
  //const outputTextRef = useRef(null);
  console.log("Avatar streaming:");
  const talkVideoRef = useRef(null);

  //const peerConnectionRef = useRef(null);
  const peerConnectionRef = useRef([
    { id: 0, connection: null, active: false },
    { id: 1, connection: null, active: false },
    { id: 2, connection: null, active: false },
    { id: 3, connection: null, active: false },
  ]);
  const userRefIndexConnection = useRef(0);
  let indexConnection = userRefIndexConnection.current;

  const streamIdRef = useRef(null);
  const sessionIdRef = useRef(null);
  //const usuario = "Elena"; // ver usuario, poasar a variable no es constante

  const SPEECH_KEY = '0c029cad0e45489fa76bca71569b0f3e';
  const SPEECH_REGION = 'westeurope';
  //const IMG_URL_ROIG = 'https://create-images-results.d-id.com/auth0%7C646f6fd64196da85cb62a776/upl_dtCm20p57vc6Gz-kKC8oW/image.jpeg';
  
  //const [avatarType, setAvatarType] = useState('algún_valor_inicial');
  const [avatarImgUrl, setAvatarImgUrl] = useState('');
  const [azureVoiceId, setAzureVoiceId] = useState('');
  const [speechRecognitionLanguage, setSpeechRecognitionLanguage] = useState('');
  //const [avatar, setAvatar] = useState(''); // Changed from useRef to useState
  const [videoInicial, setVideoInicial] = useState('');
  
  
  //// Removed this line as it's now managed by useState
  //// Removed this line as it's now managed by useState
  //const avatar = useRef('roig');
  //// Removed this line as it's now managed by useState
  //variable modo video que solo puede ser VIDDEO o AUDIO
 // const modoVideoAudioChat = useRef('VIDEO');

 const urlParams = new URLSearchParams(window.location.search);
 const avatarType = urlParams.get('avatar');
 const usuario = urlParams.get('usuario');
 console.log("avatarType:", avatarType);
 //setDisplayText(avatarType);

  useEffect(() => {
    // Fetching avatar configuration based on avatarType
    (async () => {
      const url = `https://ceu-chatcompletion-python.azurewebsites.net/api/ceuavatarconfigure?avatar_type=${avatarType}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const jsonResponse = await response.json();
          setAvatarImgUrl(jsonResponse['avatarImgUrl']);
          setAzureVoiceId(jsonResponse['azureVoiceId']);
          setSpeechRecognitionLanguage(jsonResponse['speechRecognitionLanguage']);
          setVideoInicial(jsonResponse['videoInicial']);
          //setAvatar(avatarType);

        }
      } catch (error) {
        console.error('Hubo un error:', error);
      }
      console.log('url:', url);
    })();
  }, [avatarType]); // Added avatarType to the dependency array


  // ##################################################
  /// función speech to text desde el micro
  // ##################################################

  const getTokenOrRefresh = async () => {
    const response = await fetch(`https://${SPEECH_REGION}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, {
      method: "POST",
      headers: {
        "Ocp-Apim-Subscription-Key": SPEECH_KEY,
      },
    });

    const authToken = await response.text();
    return { authToken, region: SPEECH_REGION };
  };

  useEffect(() => {
    const initialize = async () => {
      const tokenRes = await getTokenOrRefresh();
      if (tokenRes.authToken === null) {
        setDisplayText(`FATAL_ERROR: ${tokenRes.error}`);
      }
    };
    initialize();

  }, []);

  const sttFromMic = async () => {
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    speechConfig.speechRecognitionLanguage = speechRecognitionLanguage;
    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
    setDisplayText("espera un momento...");
    await connect(0);
    setDisplayText("habla con el micro...");
    recognizer.recognizeOnceAsync((result) => {
      if (result.reason === speechsdk.ResultReason.RecognizedSpeech) {
        setDisplayText(`Usuario: ${result.text}`);
        //chatInputRef.current.value = result.text; // Actualiza el input del chat con el texto reconocido

        simulateBotResponse(result.text, "user", usuario + ": ");
        talk(result.text);
      } else {
        setDisplayText(" No se ha podido reconocer Asegura que el micro funciona bien.");
      }
    });
  };
  // ###########################################

  const DID_API = {
    key: "bGljZW5zaW5nQGNldS5lcw:c_zuNVYnDitDPkm7uCmDZ",
    url: "https://api.d-id.com",
  };



  const onIceGatheringStateChange = () => {
    // Implementar la lógica aquí
  };

  function onIceCandidate(event) {
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
  
      fetch(`https://api.d-id.com/talks/streams/${streamIdRef.current}/ice`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${DID_API.key}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          candidate,
          sdpMid,
          sdpMLineIndex,
          session_id: sessionIdRef.current,
        }),
      });
    }
  };

 
  const onIceConnectionStateChange = (event) => {

    if (peerConnectionRef.current[indexConnection].connection.iceConnectionState === 'failed' || peerConnectionRef.current[indexConnection].connection.iceConnectionState === 'closed') {
      stopAllStreams();
      closePC();
    }
  }

  const onConnectionStateChange = (event) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;
    console.log(`Connection State Change: ${peerConnectionRef.current[indexConnection].connection.connectionState}`);
  };

  const onSignalingStateChange = (event) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;
    console.log(`Signaling State Change: ${peerConnectionRef.current[indexConnection].connection.signalingState}`);
  };

  const onTrack = (event) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;

    try {
        // Asegurarse de que el elemento video aún no tiene un stream asignado
        if (talkVideoRef.current.srcObject) return;

        // Asignar el stream al elemento video
        talkVideoRef.current.srcObject = event.streams[0];

        // Añadir un listener para cada track en el stream
        event.streams[0].getTracks().forEach(track => {
            track.onended = () => {
                console.log('El TRACK ha terminado');
                setDisplayText("Pulsa para hablar")
                // Aquí puedes añadir cualquier acción que desees realizar
                // cuando el track finaliza, como ocultar el video, mostrar un mensaje, etc.
            };
        });
    } catch (e) {
        console.error("Error durante onTrack:", e);
    }
};


  const stopAllStreams = async() => {
   try {
    if (talkVideoRef.current.srcObject) {
      console.log('stopping video streams');
      talkVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
      talkVideoRef.current.srcObject = null;
    }
    }
    catch (e) {
      console.error("Error during stopAllStreams:", e);
    }
  

  };

  const closePC =  () => {

   // console.log("peerConnectionRef.current:", peerConnectionRef.current.signalingState);
    console.log("CLOSE PC INVOKED");
    
    if (peerConnectionRef.current[indexConnection].connection && peerConnectionRef.current[indexConnection].connection.connectionState === 'connected'){
    console.log('stopping peer connection');

    
    peerConnectionRef.current[indexConnection].connection.close();

    peerConnectionRef.current[indexConnection].connection.removeEventListener('icegatheringstatechange', onIceGatheringStateChange);
    peerConnectionRef.current[indexConnection].connection.removeEventListener('icecandidate', onIceCandidate);
    peerConnectionRef.current[indexConnection].connection.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange);
    peerConnectionRef.current[indexConnection].connection.removeEventListener('connectionstatechange', onConnectionStateChange);
    peerConnectionRef.current[indexConnection].connection.removeEventListener('signalingstatechange', onSignalingStateChange);
    peerConnectionRef.current[indexConnection].connection.removeEventListener('track', onTrack);
    }
    peerConnectionRef.current[indexConnection].connection = null;
    peerConnectionRef.current[indexConnection].active = false;
  };

  const createPeerConnection = async (offer, iceServers,indexConnection) => {


    try {
     if (!peerConnectionRef?.current[indexConnection]?.connection)  {  
    
      console.log("peerConnectionRef.current[indexConnection].connection.connectionState:", peerConnectionRef.current[indexConnection].connection?.connectionState);
      //if ( peerConnectionRef.current?.connectionState === 'disconnected' || peerConnectionRef.current?.connectionState === 'failed' || peerConnectionRef.current?.connectionState === 'closed' || peerConnectionRef.current.connectionState === null) {
      console.log("PASO 3 ICESERVERS EN CONFIGURACION:",iceServers);
      console.log("CONECTANDO peerConnectionRef.current.connectionState:", peerConnectionRef.current?.connectionState);
      
      //const newConnection = new RTCPeerConnection(iceServers);
      //peerConnectionRef.current = new RTCPeerConnection({ iceServers });
     //console.log("conexion creada:", newConnection);
     peerConnectionRef.current[indexConnection].connection = new RTCPeerConnection({ iceServers });
     // peerConnectionRef.current = new RTCPeerConnection({ iceServers });

      
      console.log("peerConnectionRef.current[indexConnection].connection:", peerConnectionRef.current[indexConnection].connection);
      peerConnectionRef.current[indexConnection].active = true;
      if (!peerConnectionRef.current || !peerConnectionRef.current[indexConnection] || !peerConnectionRef.current[indexConnection].connection) {
        console.log("Algo está mal con la inicialización de la conexión");
        return;
      }
      
      console.log("peerConnectionRef.current:", peerConnectionRef.current[indexConnection]);
      peerConnectionRef.current[indexConnection].connection.addEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
      peerConnectionRef.current[indexConnection].connection.addEventListener('icecandidate', onIceCandidate, true);
      peerConnectionRef.current[indexConnection].connection.addEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
      peerConnectionRef.current[indexConnection].connection.addEventListener('connectionstatechange', onConnectionStateChange, true);
      peerConnectionRef.current[indexConnection].connection.addEventListener('signalingstatechange', onSignalingStateChange, true);
      peerConnectionRef.current[indexConnection].connection.addEventListener('track', onTrack, true);
    //}
   } 
    }catch (e) {
      console.error("Error during createPeerConnection:", e);
    }
      
    
      console.log("iceserver", iceServers);
      console.log("peerConnectionRef.current:", peerConnectionRef.current[indexConnection]);
      console.log("offer:", offer);
      await peerConnectionRef.current[indexConnection].connection.setRemoteDescription(offer);
      
      const answer = await peerConnectionRef.current[indexConnection].connection.createAnswer();
      
      await peerConnectionRef.current[indexConnection].connection.setLocalDescription(answer);
      console.log("answer de create connection:", answer);
   
      console.log("peerConnectionRef.current:", peerConnectionRef.current);
    
      return answer;
    
  };
  

  const connect = async (indexConnection) => {
    console.log("Connect function invoked");
    if (peerConnectionRef.current[indexConnection].connection && peerConnectionRef.current[indexConnection].connection.connectionState === 'connected') {
      console.log("Already connected, exiting function");
      return;
    }
     stopAllStreams();
     closePC();

    try {
      console.log("Trying to establish connection with D-ID API");
      const sessionResponse = await fetch(`${DID_API.url}/talks/streams`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${DID_API.key}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          source_url: avatarImgUrl
        }),
      });

      const responseData = await sessionResponse.json();
      console.log("Received response from D-ID API:", responseData);
      const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = responseData;
      streamIdRef.current = newStreamId;
      sessionIdRef.current = newSessionId;
      console.log("StreamId:", streamIdRef.current);
      console.log("SessionId:", sessionIdRef.current);

      console.log("CREAMOS LA CONEXIÓN PeerConnection:");
      console.log("Offer:", offer);
      //llamada a creación de la conexión webRTC
      const sessionClientAnswer = await createPeerConnection(offer, iceServers, indexConnection);
      
      
      console.log("peerConnectionRef.current?.connectionState:", peerConnectionRef.current[indexConnection].connection?.connectionState);
      console.log("Trying to send answer to D-ID API");
      await fetch(`${DID_API.url}/talks/streams/${streamIdRef.current}/sdp`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${DID_API.key}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ answer: sessionClientAnswer, session_id: sessionIdRef.current })
      });
      console.log("Connection established successfully");
    } catch (e) {
      console.error("Error during connection:", e);
    }
  };
  if (talkVideoRef.current) {
 // talkVideoRef.current.play();
}

  const talk = async (user_message) => {
  
    console.log("Talk function invoked");
    if (peerConnectionRef.current[indexConnection].connection.signalingState !== 'stable' || peerConnectionRef.current[indexConnection].connection.iceConnectionState !== 'connected') {
      console.log("Peer connection is not stable or not connected, exiting function");

      return;
    }

    try {
      console.log("Trying to get chat response");


      setIsBotThinking(true);
      const response = await fetch(`https://ceu-chatcompletion-python.azurewebsites.net/api/ceuavatarcompletion?session_id=${streamIdRef.current}&mensaje=${user_message}&usuario=${usuario}&avatar=${avatarType}`);
      let chatText = await response.text();
      setIsBotThinking(false);
      simulateBotResponse(chatText, "bot", "Avatar ")
      //solo si simulateBotResponse(user_message) ha finalizado continua
      

      //let chatText = messages[messages.length-1].text;
      console.log("Received chat response:", chatText);
      console.log("streamIdRef.current:", streamIdRef.current);
      setDisplayText(`Avatar: ${chatText}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      //outputTextRef.current.textContent = chatText;
      console.log("Trying to initiate video streaming with D-ID API");
      await fetch(`${DID_API.url}/talks/streams/${streamIdRef.current}`, {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${DID_API.key}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          script: {
            type: 'text',
            subtitles: 'false',
            provider: {
              type: 'microsoft',
              voice_id: azureVoiceId
            },
            ssml: 'false',
            input: chatText
          },
          driver_url: 'bank://lively/',
          config: {
            stitch: true,
          },
          session_id: sessionIdRef.current
        })
      });
      console.log("Talk function completed successfully");
    } catch (e) {
      console.error("Error during talk:", e);
    }
    
  };


// #############################
// Gestión del render del video y streaming

  const [showStreaming, setShowStreaming] = useState(true); //deberñia ser false, para ver el video de vimeo


  const handleVideoEnd = () => {
    // Cambiar a tu streaming en vivo aquí
    setShowStreaming(true);
    

    // Iniciar tu streaming y asignar el stream al elemento de video
    // Por ejemplo: talkVideoRef.current.srcObject = tuStream;
  };
  

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (talkVideoRef.current) {
      talkVideoRef.current.poster = avatarImgUrl;
    }
    // eslint-disable-next-line
  }, []); // Las dependencias están vacías, por lo que esto se ejecutará sólo una vez después del montaje del componente
  

// ###########################################
 /// función visualizacion del chat zona texto
 // ###########################################
 const [messages, setMessages] = useState([]);
 // const [newMessage, setNewMessage] = useState('');
 const [isBotThinking, setIsBotThinking] = useState(false);
 const endOfMessagesRef = useRef(null);
 

 
 const simulateBotResponse = async (botMessage, senderType, senderName) => {
   
   try {
     //const response = await fetch(`https://ceu-chatcompletion-python.azurewebsites.net/api/ceuavatarcompletion?session_id=${streamIdRef.current}&mensaje=${userMessageText}&usuario=${usuario}&avatar=${avatarType}`);
     //let mensaje = await response.text();
 
    setMessages(prevMessages => [...prevMessages, { text: botMessage, sender: senderType, autor: senderName}]);
    console.log("botMessage:", botMessage);
     console.log("botMessage:", botMessage);

   } catch (error) {
     console.error("Error al obtener la respuesta del bot:", error);

   };
 };
 
 useEffect(() => {
   endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
 }, [messages]);
 
// ###########################################



  
 return (
  <div className="avatar-component">
    <div className="subarea subarea-img">
      {showStreaming ? (
        <div style={{ width: '100%', height: '100vh' }}>
        <video ref={talkVideoRef} autoPlay playsInline poster={avatarImgUrl} />
      
        <button className="subarea-img-button" onClick={sttFromMic}>{displayText}</button>

      </div>
      ) : (
        <div style={{ width: '100%', height: '100vh' }}>
          <ReactPlayer 
            url={videoInicial}
            playing={true}
            controls={true}
            width='100%'
            height='100vh'
            onEnded={handleVideoEnd}
            config={{
              vimeo: {
                playerOptions: {
                  byline: false,
                  portrait: false,
                  title: false,
                  color: 'ffffff',
                }
              },
              attributes: { style: { objectFit: 'cover' } }
            }}
          />
        </div>
      )}
    </div>
    <div className="subarea subarea-text">
        <div className="chat-container">
          <div className="messages">
            {messages.map((message, index) => (
             
              <div key={index} className={`message ${message.sender}`}> 
                <b>{message.autor}</b> :
                {message.text}  
              </div>
            ))}
            {isBotThinking && <div className="bot-thinking"><span>.</span><span>.</span><span>.</span></div>}
            <div ref={endOfMessagesRef} />
          </div>
          
        </div>
    </div>
  </div>
);
};

export default AvatarApp;