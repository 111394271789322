import React, {  useEffect } from "react";
import "./css/teachersgallery.css"
//import EnglishPricingTable from './EnglishPricingTable';
//import Menu from './Menu';





const TeachersGallery = () => {


  useEffect(() => {
    // Esto lo hago para despertar la funcion de azure que configura el avatar y luego no tarde timepo en cargar
    (async () => {
      const url = `https://ceu-chatcompletion-python.azurewebsites.net/api/ceuavatarconfigure?avatar_type=modalverbs`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          console.log('todo ok azure funcion despertada');
        }
      } catch (error) {
        console.error('Hubo un error:', error);
      }
      console.log('url:', url);
    })();
  }, []);



      const cards = [
        {
          title: 'Modal verbs',
          text: 'In this beginner\'s English course, we\'ll focus on "modal verbs" such as can, could, should, and must. You\'ll learn how to use them to express ability, possibility, obligation, and advice.',
          imgSrc: 'assets/images/teacher1-2-816x816.png',
          btnText: 'Prueba',
          btnLink: '/avatar/?avatar=modalverbs&usuario=jose',
          isInternalLink: true,
        },
        {
          title: 'Travel English',
          text: 'Equip yourself with key phrases and vocabulary for typical travel situations like booking hotels and asking for directions',
          imgSrc: 'assets/images/ingles8jpg.jpg',
          btnText: 'Prueba',
          btnLink: '/avatar/?avatar=travelenglish&usuario=laura',
          isInternalLink: true,
        },
        {
          title: 'Phrasal Verbs',
          text: 'Learn to navigate common phrasal verbs used in daily conversations and professional settings to enhance communication skills.',
          imgSrc: 'assets/images/ingles14-596x596.png',
          btnText: 'Prueba',
          btnLink: '/avatar/?avatar=phrasalverbs&usuario=jose',
          isInternalLink: true,
        },
        {
          title: 'Business English',
          text: 'Focus on specialized vocabulary and phrases used in business contexts, such as meetings and negotiations.',
          imgSrc: 'assets/images/ingles5-596x596.png',
          btnText: 'Prueba',
          btnLink: '/avatar/?avatar=bussinessenglish&usuario=alberto',
          isInternalLink: true,
        },
        {
          title: 'Daily Conversation',
          text: 'Practice conversational English for everyday situations like shopping, asking for directions, and making friends.',
          imgSrc: 'assets/images/ingles4-955x955.png',
          btnText: 'Prueba',
          btnLink: 'https://metaversolab.es/?avatar=profesor_ingles&usuario=leo'
        },
        {
          title: 'Technical English',
          text: 'Learn industry-specific vocabulary and expressions used in technical fields like engineering, IT, and sciences. ',
          imgSrc: 'assets/images/ingles10-955x955.png',
          btnText: 'Prueba',
          btnLink: 'https://metaversolab.es/?avatar=profesor_ingles&usuario=leo'
        },
        {
          title: 'Academic English',
          text: 'Gain tips and vocabulary for writing high-quality academic essays, reports, and emails.',
          imgSrc: 'assets/images/ingles7-596x596.png',
          btnText: 'Prueba',
          btnLink: 'https://metaversolab.es/?avatar=profesor_ingles&usuario=leo'
        },
        {
          title: 'Pronunciation Essentials',
          text: 'Work on challenging sounds and pronunciation rules to speak more fluently and clearly.',
          imgSrc: 'assets/images/teacher1-2-816x816.png',
          btnText: 'Prueba',
          btnLink: 'https://metaversolab.es/?avatar=profesor_ingles&usuario=leo'
        },
        // ...añadir otros objetos de tarjeta aquí
      ];
    
      return (
        <>
        
          <section data-bs-version="5.1" className="gallery3 cid-tSXLDp0shU" id="gallery3-2v">
            <div className="container">
              <div className="mbr-section-head">
                <h3 className="mbr-section-title  mb-0 ">1 hour English courses</h3>
              </div>
              <div className="row mt-5">
                {cards.slice(0, 8).map((card, index) => (
                  <div key={index} className="item features-image col-12 col-md-6 col-lg-3">
                    <div className="item-wrapper">
                      <div className="item-img">
                        <img src={card.imgSrc} alt={card.title} />
                      </div>
                      <div className="item-content">
                        <h5 className="item-title  display-5">{card.title}</h5>
                        <p className="mbr-text   mt-3 display-7">{card.text}</p>
                      </div>
                      <div className="mbr-section-btn item-footer mt-2">
                        <a href={card.btnLink} className="btn item-btn btn-info display-4"  rel="noopener noreferrer">{card.btnText}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

        </>
      );
    }
    
    export default TeachersGallery;
    

    
